import '../../App.css';
import React, {useEffect, useState, useCallback} from "react";
import Header from "../Header/Header";
import {ENGLISH} from "../../constants/language";
import {ARTICLES} from "../../constants/menuTypes";
import {motion} from "framer-motion";
import './../test/styles.css';
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import {useDispatch} from "react-redux";
import {getArticles} from "../../actions/Articles";
import { useHistory } from 'react-router-dom'
import Aos from 'aos';
import Article from "./Article";

const Articles = ({language, articles}) => {


    const projectLanguage = language === ENGLISH ? ARTICLES.eng : ARTICLES.heb;

    const transition = { duration: 0.5, ease: "easeInOut" };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getArticles());
    },[])

    const blogVariants = {
        enter: { transition: { staggerChildren: 0.1 } },
        exit: { transition: { staggerChildren: 0.1 } }
    };

    const postVariants = {
        initial: { y: 100, opacity: 0 },
        enter: { y: 0, opacity: 1, transition },
        exit: { y: 100, opacity: 0, transition }
    };

    const postPreviewVariants = {
        initial: { x: 100, opacity: 0 },
        enter: { x: 0, opacity: 1, transition },
        exit: { x: -100, opacity: 0, transition }
    };



    return(
        <>
            {articles.length > 0 &&
                <motion.div className=""
                            initial="initial"
                            animate="enter"
                            exit="exit"
                            variants={postVariants}
                >
                    <Header header={projectLanguage} image='./images/img-6.jpg'/>

                    <div className="page hover" >
                        {articles.length > 0 && articles.map(article =>
                                article.images.length !== 0 &&
                                    <Article key={article._id} article={article} language={language}/>
                            )}
                    </div>
                </motion.div>
            }
        </>
    );
};

export default Articles;

