import React, {useCallback, useEffect, useState} from 'react';
import {useParams, useHistory, Link} from 'react-router-dom';
import CircularProgress from "@material-ui/core/CircularProgress";
import {ENGLISH} from "../../constants/language";
import { AnimateSharedLayout,motion, AnimatePresence} from 'framer-motion';
import ImagePopup from "./ImagePopup";
import './project.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './../test/styles.css'
import ImageZoom from "react-medium-image-zoom";
import {Grid} from "@material-ui/core";
import Aos from 'aos';
import {SUBJECTS_TYPES} from "../../constants/subjectTypes";

import './proj.css';
import {useDispatch} from "react-redux";
import {getProjects} from "../../actions/projects";

const Project = ({language, projects}) => {

    const [popup, setPopup] = useState(false);
    const [layoutId, setLayoutId] = useState(null);

    const poper = (id) => {
        if(popup===false) {
            setLayoutId(id);
            setPopup(!popup);
        } else {
            setPopup(!popup);
            setLayoutId(null);
        }
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProjects());
    }, [projects])

    const history = useHistory();

    const { id } = useParams();

    const project = projects.find(proj => proj.title_id === id);


    if(projects.length >0)
        var projectLanguage = language === ENGLISH ? project.eng : project.heb;



    const transition = { duration: 0.5, ease: "easeInOut" };


    const postVariants = {
        initial: { y: 100, opacity: 0 },
        enter: { y: 0, opacity: 1, transition },
        exit: { y: -100, opacity: 0, transition }
    };

    const subject = () => {
        if(project) {
            const subjectID = SUBJECTS_TYPES.find(sub => project.subject === sub.id);
            if(language === ENGLISH)
                return subjectID.eng;
            else
                return subjectID.heb;
        }
    };

    const [isZoomed, setIsZoomed] = useState(false)

    const handleImgLoad = useCallback(() => {
        setIsZoomed(true)
    }, [])

    const handleZoomChange = useCallback(shouldZoom => {
        setIsZoomed(shouldZoom)
    }, [])

    return (
        <>
            {!project ? <CircularProgress/> :
                <div>


                    {/*<motion.div*/}
                    {/*    className="page_"*/}
                    {/*    initial="exit"*/}
                    {/*    animate="enter"*/}
                    {/*    exit="exit"*/}
                    {/*    variants={postVariants}*/}
                    {/*>*/}

                    {/*    <div className="post">*/}
                    {/*        <img className="post__img" src={project.images[0]} alt={project.images[0]} />*/}
                    {/*        <div className="link-wrapper">*/}
                    {/*            <Link to="/projects">*/}
                    {/*                <ArrowBackIcon fontSize={"large"} className={'back-button'} />*/}
                    {/*            </Link>*/}
                    {/*        </div>*/}


                    {/*        <h1 className="heading">{subject()}</h1>*/}
                    {/*        <h1 className="heading">{projectLanguage.title}</h1>*/}



                    {/*        <Grid container style={{justifyContent: 'space-between'}} spacing={1}>*/}
                    {/*            <Grid item xs={12} sm={8}>*/}
                    {/*                <div className="project_description">*/}
                    {/*                    description: <p>{projectLanguage.description}</p>*/}
                    {/*                    client: <p>{projectLanguage.client}</p>*/}
                    {/*                    <p>{projectLanguage.architectures}</p>*/}
                    {/*                    <p>{projectLanguage.simulation}</p>*/}
                    {/*                    <p>{projectLanguage.partners}</p>*/}
                    {/*                    <p>{projectLanguage.interior_designer}</p>*/}
                    {/*                    <p>{projectLanguage.photography}</p>*/}
                    {/*                    <p>{projectLanguage.project_manager}</p>*/}
                    {/*                </div>*/}
                    {/*            </Grid>*/}
                    {/*            <Grid item xs={12} sm={4}>*/}
                    {/*                <div className="image_Gallery">*/}
                    {/*                    <Grid  component="div" container justifyContent="center" alignItems="center" spacing={3}>*/}
                    {/*                        {project.images.map((img) => (*/}
                    {/*                            <div className="project_cards">*/}
                    {/*                                <ImageZoom*/}
                    {/*                                    image={{*/}
                    {/*                                        src: img, alt: img, className: "img1"*/}
                    {/*                                    }}*/}
                    {/*                                    zoomImage={{*/}
                    {/*                                        src: img, alt: img, className: ""*/}
                    {/*                                    }}*/}
                    {/*                                />*/}
                    {/*                            </div>*/}
                    {/*                        ))}*/}
                    {/*                    </Grid>*/}
                    {/*                </div>*/}
                    {/*            </Grid>*/}
                    {/*        </Grid>*/}







                    {/*    </div>*/}
                    {/*</motion.div>*/}


                    <div className="App">
                                <div className="details">
                                    <div className="big-img">
                                        <img src={'https://yossi-rokach-server.herokuapp.com/imagesBucket/'+project.images[0]} alt="main-img" />
                                    </div>

                                    <div className="box">
                                        <div className="row">
                                            <h2><span>{project.heb.title}</span></h2>
                                            <h3><span>{projectLanguage.description}</span></h3>
                                        </div>

                                        <p>description: {projectLanguage.description}</p>
                                        <p>client: {projectLanguage.client}</p>
                                        <p>{projectLanguage.architectures}</p>
                                        <p>{projectLanguage.simulation}</p>
                                        <p>{projectLanguage.partners}</p>
                                        <p>{projectLanguage.interior_designer}</p>
                                        <p>{projectLanguage.photography}</p>
                                        <p>{projectLanguage.project_manager}</p>

                                    </div>
                                    <div className="thumb">

                                        {project.images.map((img) => (
                                            <ImageZoom
                                                image={{
                                                    src: 'https://yossi-rokach-server.herokuapp.com/imagesBucket/'+img, alt: img, className: ""
                                                }}
                                                zoomImage={{
                                                    src: 'https://yossi-rokach-server.herokuapp.com/imagesBucket/'+img, alt: img, className: ""
                                                }}
                                            />
                                        ))}
                                    </div>

                                </div>
                    </div>
                </div>


            }
        </>
    )
};

export default Project;