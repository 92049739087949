import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import {Button} from '@material-ui/core';
import {ABOUT, ARTICLES, CONTACT, HOME, MENU_TYPES_ARRAY, PROJECTS} from "../../constants/menuTypes";
import {ENGLISH, HEBREW} from "../../constants/language";
import {motion} from "framer-motion";
import {AnimatePresence} from "framer-motion";

const Navbar = ( {language, setLanguage, isEmptyArticles} ) => {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const [navbar, setNavbar] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    const orderMenuItem = language === ENGLISH ? MENU_TYPES_ARRAY : MENU_TYPES_ARRAY.slice(0).reverse();

    var menuItem;


    useEffect(() => {
        showButton();
        if(!isEmptyArticles) {
            menuItem = orderMenuItem.filter(item =>
                item.id !== ARTICLES.id
            );
        }
        console.log(isEmptyArticles);
    }, [isEmptyArticles]);

    window.addEventListener('resize', showButton);


    var scrollUp = 0;

    const changeBackground = () => {


        if(window.scrollY >= 80) {
            setNavbar(true);
        }
        else setNavbar(false);

        if(window.scrollY < scrollUp) {
            console.log(scrollUp);
        } else {
            console.log(scrollUp);
            scrollUp = window.screenY;
        }

    };

    window.addEventListener('scroll', changeBackground);



    //const transition = { duration: 0.5, ease: "easeInOut" };

    const transition = {
        duration: 1,
        ease: [0.43, 0.13, 0.23, 0.96]
    };

    const hebrewSubjects = {
        initial: { x: '100vw', opacity: 0 },
        enter: { x: 0, opacity: 1},
        exit: { transition }
    };

    const englishSubjects = {
        initial: { x: '-100vw', opacity: 0 },
        enter: { x: 0, opacity: 1 },
        exit: { transition },
    };

    const englishMenu = () => {
        return (orderMenuItem.map(menuItem => (
            <motion.li className={'nav-item'}
                       key={menuItem.eng}
                       initial={'initial'}
                       animate={'enter'}
                       exit={'exit'}
                       variants={englishSubjects}
            >
                <Link to={menuItem.to} className={'nav-links'} onClick={closeMobileMenu}>
                    {menuItem.eng}
                </Link>
            </motion.li>
        )));
    };

    const hebrewMenu = () => {
        return (orderMenuItem.map(menuItem => (

            <motion.li className={'nav-item'}
                       key={menuItem.heb}
                       initial={'initial'}
                       animate={'enter'}
                       exit={'exit'}
                       variants={hebrewSubjects}
            >
                <Link to={menuItem.to} className={'nav-links'} onClick={closeMobileMenu}>
                    {menuItem.heb}
                </Link>
            </motion.li>
        )));
    };

    const menu = () => {
           if (language === ENGLISH) {
               return englishMenu();
           } else {
               return hebrewMenu();
           }
        };


    return (
        <>
            <nav className={navbar ? 'navbar active' : 'navbar'}>
                <div className='navbar-container'>
                    <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                        <img src='./../images/YR-LD-LOGO.png' alt='Yossi Rokach'/>
                        {/*<i className='fab fa-typo3' />*/}
                    </Link>
                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>

                        {menu()}

                        {/*{orderMenuItem.map(menuItem => (*/}
                        {/*    <li className='nav-item'*/}
                        {/*               initial="initial"*/}
                        {/*               animate="enter"*/}
                        {/*               exit="exit"*/}
                        {/*               variants={postPreviewVariants}*/}
                        {/*    >*/}
                        {/*        <Link to={menuItem.to} className='nav-links' onClick={closeMobileMenu}>*/}
                        {/*            {language === ENGLISH ? menuItem.eng : menuItem.heb}*/}
                        {/*        </Link>*/}
                        {/*    </li>*/}
                        {/*))}*/}

                        {/*<li className='nav-item'>*/}
                        {/*    <Link to='/' className='nav-links' onClick={closeMobileMenu}>*/}
                        {/*        {language === ENGLISH ? HOME.eng : HOME.heb}*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                        {/*<li className='nav-item'>*/}
                        {/*    <Link to='/about' className='nav-links' onClick={closeMobileMenu}>*/}
                        {/*        {language === ENGLISH ? ABOUT.eng : ABOUT.heb}*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                        {/*<li className='nav-item'>*/}
                        {/*    <Link*/}
                        {/*        to='/projects'*/}
                        {/*        className='nav-links'*/}
                        {/*        onClick={closeMobileMenu}*/}
                        {/*    >*/}
                        {/*        {language === ENGLISH ? PROJECTS.eng : PROJECTS.heb}*/}
                        {/*    </Link>*/}
                        {/*</li>*/}

                        {/*<li className='nav-item'>*/}
                        {/*    <Link*/}
                        {/*        to='/contact'*/}
                        {/*        className='nav-links'*/}
                        {/*        onClick={closeMobileMenu}*/}
                        {/*    >*/}
                        {/*        {language === ENGLISH ? CONTACT.eng : CONTACT.heb}*/}
                        {/*    </Link>*/}
                        {/*</li>*/}

                        {/*<li>*/}
                        {/*    <Link*/}
                        {/*        to='/sign-up'*/}
                        {/*        className='nav-links-mobile'*/}
                        {/*        onClick={closeMobileMenu}*/}
                        {/*    >*/}
                        {/*        Sign Up*/}
                        {/*    </Link>*/}
                        {/*</li>*/}



                    </ul>

                    {language === ENGLISH ?
                        <Button
                            className='nav-links'
                            onClick={() => {
                                setLanguage (HEBREW);
                            }}
                        >
                            <p className={'btnLng'}>
                                Hebrew
                            </p>
                        </Button> :
                        <Button
                            className='nav-links'
                            onClick={() => {
                                setLanguage (ENGLISH);
                            }}
                        >
                            <p className={'btnLng'}>
                                English
                            </p>
                        </Button>
                    }

                    {/*{button && <Button buttonStyle='btn--outline'>SIGN UP</Button>}*/}
                </div>
            </nav>
        </>
    );
};

export default Navbar;