
export const SliderData = [
    {
        title: 'image 1',
        price: '####',
        path: '/home',
        label: 'view Home',
        image: '/images/Home-1.jpg',
        alt: 'House'
    },
    {
        title: 'image 2',
        price: '####',
        path: '/home',
        label: 'view Home',
        image: '/images/Home-2.jpg',
        alt: 'House'
    },
    {
        title: 'image 3',
        price: '####',
        path: '/home',
        label: 'view Home',
        image: '/images/Home-3.png',
        alt: 'House'
    },
    {
        title: 'image 4',
        price: '####',
        path: '/home',
        label: 'view Home',
        image: '/images/Home-4.png',
        alt: 'House'
    },
];