import React, {useEffect} from 'react';
import {CircularProgress, Grid} from "@material-ui/core";
import CardsItem from "./CardsItem";
import {All} from "../../constants/subjectTypes";
import './project.css';
import Aos from "aos";

const Cards = ({language, projects, subject}) => {

    useEffect(() => {
        Aos.init({duration: 1000});
    });

    const projectsFilter = projects.filter((project) =>  {
        if(subject === All.id) {
            return true;
        }
        else {
            return project.subject === subject;
        }
    });

    return (
        <>
            <div className="wrapper-projects">
            {!projectsFilter.length ? <CircularProgress /> : (
                <Grid component="span" container justifyContent="center" alignItems="center" spacing={3}>
                    {
                        projectsFilter.map((project) => (
                            project.images.length !== 0 ?
                                <div data-aos="fade-up">
                                <CardsItem item key={project._id} project={project} language={language}/>
                                </div>
                                : null
                        ))
                    }
                </Grid>
            )}
            </div>
        </>
    )
};

export default Cards;