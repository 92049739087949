import { combineReducers} from 'redux';

import projects from './projects';
import articles from './Articles';


const rootReducer = combineReducers({
    projects, articles
})

export default rootReducer;
