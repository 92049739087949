import React, {useEffect} from 'react';
import Header from "../Header/Header";
import CONTACT_INFO from "../../constants/contactInfo";
import {ENGLISH} from "../../constants/language";

import BusinessIcon from '@material-ui/icons/Business';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import './Contact.css';
import {motion} from "framer-motion";
import Aos from "aos";

const Contact = ({language}) => {

    const contact = language === ENGLISH ? CONTACT_INFO.eng : CONTACT_INFO.heb;

    const transition = { duration: 0.5, ease: "easeInOut" };

    const postVariants = {
        initial: { y: 100, opacity: 0 },
        enter: { y: 0, opacity: 1, transition },
        exit: { y: 100, opacity: 0, transition }
    };

    const containerStyle = {
        width: '400px',
        height: '400px'
    };

    const center = {
        lat: 32.2866223, lng: 34.8554982
    };

    useEffect(() => {
        Aos.init({duration: 1000});
    });

    return (
        <>

            <motion.div className=""
                        initial="initial"
                        animate="enter"
                        exit="exit"
                        variants={postVariants}
            >
                <Header header={contact.id} image='./images/img-3.jpg' size={'small'}/>

            {language === ENGLISH ?
                <div className={`AboutContainer ${language !== ENGLISH ? 'aboutContainerHeb' : ''}`}>
                    <div className='Description'>
                        {/*<p>{contact.id}</p>*/}
                    </div>
                    <div className='ContentWrapper'>
                        <div className='ColumnOne'>
                            <div className='About'>
                                <h3 data-aos="fade-right" data-aos-once="true">{contact.title}</h3>
                                <h2 data-aos="fade-right" data-aos-once="true">{contact.sub_title}</h2>
                                <p data-aos="fade-right" data-aos-once="true">{contact.description}</p>
                                <div data-aos="fade-right" data-aos-once="true" className={'textDiv'}><LocalPhoneIcon/><p
                                    className={`sameLine ${language !== ENGLISH ? 'textHeb' : ''}`}>{contact.phone}</p>
                                </div>
                                <div data-aos="fade-right" data-aos-once="true" className={'textDiv'}><AlternateEmailIcon/><p
                                    className={`sameLine ${language !== ENGLISH ? 'textHeb' : ''}`}>{contact.mail}</p>
                                </div>
                                <div data-aos="fade-right" data-aos-once="true" className={'textDiv'}><BusinessIcon/> <p
                                    className={`sameLine ${language !== ENGLISH ? 'textHeb' : ''}`}>{contact.address}</p>
                                </div>
                            </div>
                        </div>
                        <div data-aos="fade-left" data-aos-once="true" className='ColumnTwo'>
                            {/*<img src="/images/img-5.jpg" alt="yossi asd"/>*/}

                            <LoadScript
                                googleMapsApiKey="AIzaSyAksdmSzfdFe8z2paE-AQXDkU-YBN5MX0I"
                            >
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={center}
                                    zoom={15}
                                >
                                    <Marker position={center}/>
                                    { /* Child components, such as markers, info windows, etc. */}
                                    <></>
                                </GoogleMap>
                            </LoadScript>
                        </div>
                    </div>
                </div>
                :
                <div>
                <div className={`AboutContainer ${language !== ENGLISH ? 'aboutContainerHeb' : ''}`}>
                    <div className='Description'>
                        {/*<p>{contact.id}</p>*/}
                    </div>
                    <div className='ContentWrapper'>
                        <div className='ColumnOne'>
                            <div className='About'>
                                <h3 data-aos="fade-left" data-aos-once="true">{contact.title}</h3>
                                <h2 data-aos="fade-left" data-aos-once="true">{contact.sub_title}</h2>
                                <p data-aos="fade-left" data-aos-once="true">{contact.description}</p>
                                <div data-aos="fade-left" data-aos-once="true" className={'textDiv'}><LocalPhoneIcon/><p
                                    className={`sameLine ${language !== ENGLISH ? 'textHeb' : ''}`}>{contact.phone}</p>
                                </div>
                                <div data-aos="fade-left" data-aos-once="true"className={'textDiv'}><AlternateEmailIcon/><p
                                    className={`sameLine ${language !== ENGLISH ? 'textHeb' : ''}`}>{contact.mail}</p>
                                </div>
                                <div data-aos="fade-left" data-aos-once="true" className={'textDiv'}><BusinessIcon/> <p
                                    className={`sameLine ${language !== ENGLISH ? 'textHeb' : ''}`}>{contact.address}</p>
                                </div>
                            </div>
                        </div>
                        <div data-aos="fade-right" data-aos-once="true" className='ColumnTwo'>
                            {/*<img src="/images/img-5.jpg" alt="yossi asd"/>*/}

                            <LoadScript
                                googleMapsApiKey="AIzaSyAksdmSzfdFe8z2paE-AQXDkU-YBN5MX0I"
                            >
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={center}
                                    zoom={15}
                                >
                                    <Marker position={center}/>
                                    { /* Child components, such as markers, info windows, etc. */}
                                    <></>
                                </GoogleMap>
                            </LoadScript>
                        </div>
                    </div>
                </div>
                </div>
            }
            </motion.div>

        </>
    );
};

export default Contact;
