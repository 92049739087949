import React, {useEffect, useState} from 'react';
import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Home from "./components/Home/Home";
import Projects from "./components/Projects/Projects";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {getProjects} from "./actions/projects";
import {ENGLISH} from "./constants/language";
import Project from "./components/Projects/Project";
import {AnimatePresence} from "framer-motion";
import ScrollToTop from "./components/ScrollToTop";
import Articles from "./components/Articles/Articles";
import {getArticles} from "./actions/Articles";

const App = () => {

    const [language, setLanguage] = useState(ENGLISH);

    const [match, setMatch] = useState(0);

    const projects = useSelector(state => state.projects, shallowEqual);
    const articles = useSelector(state => state.articles, shallowEqual);

    // const [projects, articles] = useSelector((state) => [
    //     state.projs,
    //     state.artics,
    // ]);


    console.log(articles);
    console.log(projects);

    const [isEmptyArticles, setIsEmptyArticles] = useState(false);

    if(projects.length > 0) {
        projects.forEach((project) => {
            project.title_id = editUrl(project.eng.title);
        })
    }

    function editUrl(title) {
        let titlePath = title.trim();
        titlePath = titlePath.split('-').join('');
        titlePath = titlePath.split(/\s+/g).join('-');
        return titlePath;
    }


    // const dispatch = useDispatch();
    //
    // useEffect(() => {
    //     // dispatch(getArticles());
    //     // dispatch(getProjects());
    //     // if(articles.length > 0) {
    //     //     setIsEmptyArticles(true);
    //     // }
    // }, []);


    return (
        <>
            <AnimatePresence >
            <Router>

            <Navbar language={language} setLanguage={setLanguage} isEmptyArticles={isEmptyArticles}/>

                <ScrollToTop/>

                <Switch>

                    {/*<Route exact path='/projects' lazy={false}>*/}
                    {/*    <Projects language={language} projects={projects} />*/}
                    {/*</Route>*/}
                    {/*<Route path='/projects/:id' lazy={false}>*/}
                    {/*    <Project language={language} projects={projects}/>*/}
                    {/*</Route>*/}
                    <Route
                        render={({ location }) => (
                            <AnimatePresence exitBeforeEnter  initial={false} >
                                <Switch location={location} key={location.pathname}>
                                    <Route exact path='/' component={Home}>
                                        <Home language={language} projects={projects}/>
                                    </Route>
                                    <Route path='/about'   >
                                        <About language={language}/>
                                    </Route>

                                    <Route exact  path="/projects">
                                        <Projects language={language} projects={projects}/>
                                    </Route>
                                    <Route  path="/projects/:id">
                                        <Project language={language} projects={projects}/>
                                    </Route>

                                    <Route   path="/articles">
                                        <Articles language={language} articles={articles}/>
                                    </Route>

                                    <Route path='/contact'>
                                        <Contact language={language}/>
                                    </Route>
                                </Switch>
                            </AnimatePresence>
                        )}
                    />
                </Switch>
                <Footer/>
            </Router>
            </AnimatePresence>
        </>
    );
};

export default App;