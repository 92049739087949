import { FETCH_ALL } from "../constants/actionTypes";

const projects =  (projects = [] , action) => {
    if (action.type === FETCH_ALL) {
        return action.payload;
    } else {
        return projects;
    }
};

export default projects;