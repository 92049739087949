import React, {useEffect} from 'react';
import Aos from "aos";
import {ENGLISH} from "../../constants/language";
import {Card, CardActionArea, CardMedia, CardContent, Typography, makeStyles, Link} from "@material-ui/core";
import {useStyles} from "./ArticleStyle";
import {motion} from "framer-motion";
import Header from "../Header/Header";

const Article = ({article, language}) => {

    const classes = useStyles();

    useEffect(() => {
        Aos.init({duration: 1000});
    });

    const articleLanguage = language === ENGLISH ? article.eng : article.heb;


    return (
        <>
            <div data-aos="fade-up" key={article.title_id} className={' '}>
                <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={article.link}
                    className={`${classes.link} ${classes.hover}`}
                    color="primary"
                    aria-label=""
                >
                    {language === ENGLISH ?
                    <div >
                    <Card  component="li" className={classes.root}>
                        <CardActionArea
                            component="div"
                            className={classes.card}
                            aria-disabled="true"
                            role="presentation"
                            tabIndex="-1"
                        >
                            <CardMedia
                                data-aos="fade-left"
                                className={`${classes.media} ${classes.mediaEng}`}
                                image={article.images.length > 0 && article.images[0]}
                            />
                            <CardContent
                                className={`${classes.content} ${classes.contentEng}`}>
                                <Typography gutterBottom variant="h5" component="h2" data-aos="fade-right" data-aos-once="true">
                                    { articleLanguage.title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    paragraph
                                    data-aos="fade-right"
                                    data-aos-once="true"
                                >
                                    {articleLanguage.description}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    </div> :
                            <Card  component="li" className={classes.root}>
                                <CardActionArea
                                    component="div"
                                    className={classes.card}
                                    aria-disabled="true"
                                    role="presentation"
                                    tabIndex="-1"
                                >
                                    <CardMedia
                                        data-aos="fade-right"
                                        className={classes.media}
                                        image={article.images.length > 0 && article.images[0]}
                                    />
                                    <CardContent
                                        className={classes.content}>
                                        <Typography gutterBottom variant="h5" component="h2" data-aos="fade-left" data-aos-once="true">
                                            { articleLanguage.title}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            paragraph
                                            data-aos="fade-left"
                                            data-aos-once="true"
                                        >
                                            {articleLanguage.description}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        }
                </Link>
            </div>
        </>
    );
};

export default Article;


