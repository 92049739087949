export const Residential = {
    id: 'Residential',
    eng:'Residential',
    heb: 'מגורים'
    };
export const Offices_Commerce = {
    id: 'Offices_Commerce',
    eng:'Offices And Commerce',
    heb: 'מסחר ומשרדים'};
export const Campuses_Public_Institutions = {
    id: 'Campuses_Public_Institutions',
    eng:'Campuses And Public Institutions',
    heb: 'קמפוסים ומוסדות ציבור'};
export const Gardens_Parks = {
    id: 'Gardens_Parks',
    eng:'Gardens and parks',
    heb: 'גנים ופארקים'};
export const Urban_Design = {
    id: 'Urban_Design',
    eng:'Urban Design',
    heb: 'עיצוב עירוני'};
export const Competitions = {
    id: 'Competitions',
    eng:'Competitions',
    heb: 'תחרויות'};
export const MasterPlans = {
    id: 'MasterPlans',
    eng:'Master Plans',
    heb: 'תוכנית אב ומתאר'};

export const All = {
    id: 'ALL',
    eng: 'All',
    heb: 'כל הפרויקטים'
}

// export const ALL = {label:'ALL', name: 'ALL'};

export const SUBJECTS_TYPES = [All, Residential, Offices_Commerce, Campuses_Public_Institutions, Gardens_Parks, Urban_Design, Competitions, MasterPlans];

