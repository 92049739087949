import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import {SUBJECTS_TYPES} from "../../constants/subjectTypes";
import { useRouteMatch } from 'react-router-dom';

import {ENGLISH} from "../../constants/language";
import {motion} from "framer-motion";
import './project.css';

import Aos from 'aos';
import "aos/dist/aos.css";

const CardItem = ({project, language, show}) => {

    useEffect(() => {
        Aos.init({duration: 300});
    });


    const subject = SUBJECTS_TYPES.map(subject => {
        if(subject.id === project.subject)
            if(language === ENGLISH)
                return subject.eng;
            else return subject.heb;
        return null;
    });

    const projectLanguage = language === ENGLISH ? project.eng : project.heb;


    const fadeSide = language === ENGLISH ?  "fade-right" : "fade-left";

    return(
        <>
            <div  className={`post-preview`} >
                <div className={"card1 shadow rounded"} style={{ width: "18rem" }}>
                    <Link to={`/projects/${project.title_id}`} style={{ textDecoration: 'none' }}>
                    <img src={project.images[0]} className="card-img-top" alt={project.images[0]} />
                    <div className="">
                        {language === ENGLISH ?
                        <div data-aos={fadeSide} data-aos-once="true" key={"carItemEng"}>
                        <h5 className="card-text font-weight-bold">{projectLanguage.title}</h5>
                        <span className="badge badge-pill">{subject}</span>
                        </div> :
                            <div data-aos={fadeSide} data-aos-once="true" key={"carItemHeb"}>
                            <h5 className="card-text font-weight-bold">{projectLanguage.title}</h5>
                            <span className="badge badge-pill">{subject}</span>
                            </div>}
                    </div>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default CardItem;