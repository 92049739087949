import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles({
    root: {
        maxWidth: '900',
        listStyle: "none",
        marginBottom: "15px",
        paddingTop: "15px",
        paddingBottom: "15px",
    },
    hover: {
        "&:hover": {
            background: "#ef341e"
        },
    },
    card: {
        alignItems: "stretch",
        flexDirection: "column",
        ["@media (min-width:600px)"]: {
            flexDirection: "row",
        }
    },
    media: {
        width: "100%;",
        height: "280px",
        ["@media (min-width:600px)"]: {
            width: "30%",
            maxHeight: "240px",
            objectFit: "cover",
            float: "left",
            marginLeft: "15px"
        }
    },
    mediaEng: {
        ["@media (min-width:600px)"]: {
            float: "right",
        }
    },
    content: {
        float: "right",
        textAlign: "right"
    },
    contentEng: {
        float: "left",
        textAlign: "left"
    },
    link: {
        "&:before": {
            display: "block",
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 10
        }
    }
});

