import React from 'react';
import { Link } from 'react-router-dom';
import {SUBJECTS_TYPES} from "../../constants/subjectTypes";
import {ENGLISH} from "../../constants/language";

const CardItem = ({project, language}) => {


    const subject = SUBJECTS_TYPES.map(subject => {
        if(subject.id === project.subject)
            if(language === ENGLISH)
                return subject.eng;
            else return subject.heb;
            return null;
    });

    const projectLanguage = language === ENGLISH ? project.eng : project.heb;

    return(
        <>
           <li className='cards__item'>
               <Link className='cards__item__link' to={''}>


               <figure className='cards__item__pic-wrap' data-category={projectLanguage.title}>
                       <img src={'https://yossi-rokach-server.herokuapp.com/imagesBucket/'+project.images[0]} alt={projectLanguage.title} className='cards__item__img'/>
                   </figure>
                   <div className='cards__item__info'>
                       <h5 className='cards__item__text'>{subject}</h5>
                   </div>
               </Link>
           </li>
        </>
    );
};

export default CardItem;