import React, {useEffect} from "react";
import './About.css';
import Header from "../Header/Header";
import {ENGLISH} from "../../constants/language";
import ABOUT_INFO from "../../constants/aboutInfo";
import {ABOUT} from "../../constants/menuTypes";
import {motion} from "framer-motion";
import Aos from "aos";

const About = ({language}) => {

    useEffect(() => {
        Aos.init({duration: 1000});
    });

    const aboutLanguage = language === ENGLISH ? ABOUT.eng : ABOUT.heb;

    const about = language === ENGLISH ? ABOUT_INFO.eng : ABOUT_INFO.heb;

    const transition = { duration: 0.5, ease: "easeInOut" };

    const postVariants = {
        initial: { y: 100, opacity: 0 },
        enter: { y: 0, opacity: 1, transition },
        exit: { y: 100, opacity: 0, transition }
    };




    return(
    <>
        <motion.div className=""
                    initial="initial"
                    animate="enter"
                    exit="exit"
                    variants={postVariants}
        >
            <Header header={aboutLanguage} image='./images/img-2.jpg' size={'small'}/>

            {language === ENGLISH ?
                <div className={`AboutContainer ${language !== ENGLISH ? 'Hebrew' : ''}`}>
                    <div className='TopLine'>
                    </div>
                    <div className={`Description ${language !== ENGLISH ? 'DescriptionHeb' : ''}`}>
                        {/*<p>{aboutLanguage}</p>*/}
                    </div>
                    <div className='ContentWrapper'>
                        <div className='ColumnOne'>
                            <div className={`about ${language !== ENGLISH ? 'AboutHeb' : ''}`}>
                                <h2 data-aos="fade-right" data-aos-once="true" className={`${language !== ENGLISH ? 'TitleHeb' : ''}`}>{about.main_title}</h2>

                                {about.sub_titles.map(param => {
                                    return (
                                        <div>
                                            <h3 data-aos="fade-right" data-aos-once="true">{param.sub_title}</h3>
                                            <p data-aos="fade-right" data-aos-once="true">{param.description}</p>
                                        </div>
                                    )
                                })}
                                <div data-aos="fade-right" data-aos-once="true">
                                    <h3>{about.extras.title}</h3>
                                    <p>{about.extras.description}</p>
                                </div>
                            </div>
                        </div>
                        <div data-aos="fade-left" data-aos-once="true" className={`ColumnTwo  ${language !== ENGLISH ? 'ColumnTwoHeb' : ''}`}>
                            <img src="/images/yossi-img.jpg" alt="yossi asd"/>
                        </div>
                    </div>
                </div> :
                <div>
                    <div className={`AboutContainer ${language !== ENGLISH ? 'Hebrew' : ''}`}>
                        <div className='TopLine'>
                        </div>
                        <div className={`Description ${language !== ENGLISH ? 'DescriptionHeb' : ''}`}>
                            {/*<p>{aboutLanguage}</p>*/}
                        </div>
                        <div className='ContentWrapper'>
                            <div className='ColumnOne'>
                                <div className={`about ${language !== ENGLISH ? 'AboutHeb' : ''}`}>
                                    <h2 data-aos="fade-left" data-aos-once="true" className={`${language !== ENGLISH ? 'TitleHeb' : ''}`}>{about.main_title}</h2>

                                    {about.sub_titles.map(param => {
                                        return (
                                            <div>
                                                <h3 data-aos="fade-left" data-aos-once="true">{param.sub_title}</h3>
                                                <p data-aos="fade-left" data-aos-once="true">{param.description}</p>
                                            </div>
                                        )
                                    })}
                                    <div data-aos="fade-left" data-aos-once="true">
                                        <h3>{about.extras.title}</h3>
                                        <p>{about.extras.description}</p>
                                    </div>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-once="true" className={`ColumnTwo  ${language !== ENGLISH ? 'ColumnTwoHeb' : ''}`}>
                                <img src="/images/yossi-img.jpg" alt="yossi asd"/>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </motion.div>

    </>
    );
};

export default About;

