const CONTACT_INFO = {
  eng: {
      id: 'Contact',
      title: 'Yossi Rokach - LandScape',
      sub_title: 'Office of Planning, Development and Landscape Work',
      description: '',
      phone: '09-9696497',
      mail: 'office@yr-ld.com',
      address: 'Nethan Komoi 10, Netanya'
    },
  heb: {
      id: 'צרו קשר',
      title: 'רוקח אדריכלות נוף',
      sub_title: 'משרד תכנון עבודות פיתוח ונוף',
      description: '',
      phone: '09-9696497',
      mail: 'office@yr-ld.com',
      address: 'נתן קומוי 10, נתניה'
  }
};

export default CONTACT_INFO;