import React, {useEffect, useState} from 'react';
import './Header.css';
import {AnimatePresence, motion} from "framer-motion";
import {ENGLISH, HEBREW} from "../../constants/language";

const Header = (props, language) => {

    const englishCheck = /^[A-Za-z0-9]*$/;

    const isEnglish = englishCheck.test(props.header[0]);


    const home = () => {
        if (isEnglish) return 'Home';
        else return 'בית';
    };

    // const transition = { duration: 1 };

    const transition = {
        duration: 1,
        ease: [0.43, 0.13, 0.23, 0.96]
    };

    const hebrewSubjects = {
        exit: { x: 100, opacity: 0, transition: { delay: 1, ...transition }},
        initial: { x: -100, opacity: 0 ,delay: 2},
        enter: { x: 0, opacity: 1, transition },
    };

    const englishSubjects = {
        exit: {  x: -100, opacity: 0, transition: { delay: 1, ...transition }},
        initial: { x:  100, opacity: 0 ,delay: 2},
        enter: { x: 0, opacity: 1, transition },
    };


    const menu = () => {
        return (isEnglish) ?
        (
             <motion.div className="header-Title"
                        variants={englishSubjects}>
                <h1>{props.header}</h1>
                <div className="header-subtitle">
                    <a href="/">{home()}</a>
                    <span>/</span>
                    {props.header}
                </div>
            </motion.div>
        ) :
          (
            <div key={'12'}>
            <motion.div className="header-Title"
                        variants={hebrewSubjects}>
                <h1>{props.header}</h1>
                <div className="header-subtitle">
                    <a href="/">{home()}</a>
                    <span>/</span>
                    {props.header}
                </div>
            </motion.div>
            </div>
        );
    };


    return (
    <>
        <div className={`header ${isEnglish ? '' : 'headerHeb'} ${props.size ==='small' ? 'headerSmall' : 'headerBig'}`} style={{backgroundImage : `url(${props.image})`}}>
            <div className="header-container">
                <div className="header-image" />

                <AnimatePresence >
                <motion.div className="header-Title"
                            initial="exit"
                            animate="enter"
                            exit="exit">
                {menu()}
                </motion.div>
                </AnimatePresence>

            </div>
        </div>
    </>
    );
};

export default Header;
