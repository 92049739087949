import '../../App.css';
import HeroSection from './HeroSection';
import React, {useEffect, useState} from "react";
import Cards from "./Cards";
import {useDispatch} from "react-redux";
import {getProjects} from "../../actions/projects";
import CircularProgress from "@material-ui/core/CircularProgress";
import Hero from "./Hero";
import {SliderData} from "../../constants/SliderData";
const Home = ({language, projects}) => {

    const isLoadProject = projects[0] && projects[0].hasOwnProperty("subject");

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProjects());
    },[]);



    return(
        <>
            <Hero slides ={SliderData}/>
            {isLoadProject ? <Cards language={language} projects={projects}/>
            : <CircularProgress/>}

        </>
    );
};

export default Home;
