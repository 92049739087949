import '../../App.css';
import React, {useEffect, useState} from "react";
import Header from "../Header/Header";
import Filter from "./Filter";
import {ENGLISH} from "../../constants/language";
import {PROJECTS} from "../../constants/menuTypes";
import Cards from "./Cards";
import {All, SUBJECTS_TYPES} from "../../constants/subjectTypes";
import {motion} from "framer-motion";
import {content} from "../test/content";
import {PostPreview} from "../test/testing";
import './../test/styles.css';
import {AnimatePresence} from "framer-motion";
import {useDispatch} from "react-redux";
import {getArticles} from "../../actions/Articles";
import {getProjects} from "../../actions/projects";


const Projects = ({language, projects}) => {

    const projectLanguage = language === ENGLISH ? PROJECTS.eng : PROJECTS.heb;

    const [subject, setSubject] = useState(All.id);

    const subjectTypes = new Set();

    projects.map(project => {
        subjectTypes.add(project.subject);
    });


    const SUBJECTS = SUBJECTS_TYPES.filter(subject => {
            if(subjectTypes.has(subject.id)) return subject;
        });

    SUBJECTS.unshift(All);

    const transition = { duration: 0.5, ease: "easeInOut" };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProjects());
    },[])

    const isLoadProject = projects[0] && projects[0].hasOwnProperty("subject");

    const blogVariants = {
        enter: { transition: { staggerChildren: 0.1 } },
        exit: { transition: { staggerChildren: 0.1 } }
    };



    const postVariants = {
        initial: { y: 100, opacity: 0 },
        enter: { y: 0, opacity: 1, transition },
        exit: { y: 100, opacity: 0, transition }
    };

    const postPreviewVariants = {
        initial: { x: 100, opacity: 0 },
        enter: { x: 0, opacity: 1, transition },
        exit: { x: -100, opacity: 0, transition }
    };


    return(
        <>
            <motion.div className=""
                        initial="initial"
                        animate="enter"
                        exit="exit"
                        variants={postVariants}
            >
                <Header header={projectLanguage} image='./images/img-6.jpg'/>

            </motion.div>

            {isLoadProject &&
            <div className="page bottom_page">
                <div
                    className="blog-list"
                    initial="initial"
                    animate="enter"
                    exit="exit"
                    variants={blogVariants}
                >
                    {SUBJECTS.length > 0 ?
                        <Filter language={language} subject={subject} setSubject={setSubject} SUBJECTS={SUBJECTS}/> : <div>Loading...</div>}
                    <Cards subject={subject} language={language} projects={projects}/>
                </div>
            </div> }
        </>
    );
};

export default Projects;
