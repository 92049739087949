import React, {useEffect}  from 'react';
import CardsItem from "./CardsItem";
import { Grid, CircularProgress} from "@material-ui/core";
import { useSelector } from 'react-redux';
import './Cards.css';
import {useDispatch} from "react-redux";
import {getProjects} from "./../../actions/projects";
import {getAbouts} from "../../actions/Articles";


const Cards= ({language, projects}) => {

    return(
        <div className='cards'>
            <h1>Check out these Epic destinations!</h1>
            <div className='cards__container'>
                <div className='cards__wrapper'>
                    <ul className='cards__item'>
                        {/*<CardsItem*/}
                        {/*    src="images/img-9.jpg"*/}
                        {/*    text="Explore the hidden waterfall deep inside the amazon jungle"*/}
                        {/*    label = 'Adventure'*/}
                        {/*    path='services'*/}
                        {/*/>*/}

                        {!projects.length ? <CircularProgress /> : (
                            <Grid component="span" container alignItems="stretch" spacing={3}>
                                {
                                    projects.map((project) => (
                                        project.images.length !== 0 ?
                                            <CardsItem item key={project._id} project={project} language={language}/>
                                            : null
                                    ))
                                }
                            </Grid>
                        )}

                    </ul>
                </div>
            </div>
        </div>
    )
};

export default Cards;