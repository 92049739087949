import React from 'react';
import {InputLabel, Select, MenuItem, FormControl, Grid} from '@material-ui/core';
import {ENGLISH} from "../../constants/language";
import './project.css';

const Filter = ({language, subject, setSubject, SUBJECTS}) => {

    const searchLabel = language === ENGLISH ? 'Search Subject' : 'חפש נושא';

    const rtl = language !== ENGLISH ? 'rtl' : '';

    return (
        <>
            <div className={`formControl ${language !== ENGLISH ? 'formControlHeb' : ''}`}>
            <FormControl fullWidth size={"medium"} >
                <InputLabel style={{paddingLeft: "15px"}}>{searchLabel}</InputLabel>
                <Select name='search-subject' variant='outlined' label='Search-Subject' style={{transformOrigin: "top"}}
                        onChange={(e) => { setSubject(e.target.value) } }
                        inputProps={{
                            name: 'search-subject', variant: 'outlined', label: 'Search-Subject'
                        }}
                        value={subject}
                >
                    options={SUBJECTS.map((value) => {
                        return <MenuItem style={{direction : `${rtl}`}} key={value.id} value={value.id}>{language === ENGLISH ? value.eng : value.heb}</MenuItem>;
                })}
                </Select>
            </FormControl>
            </div>
        </>
    )
};

export default Filter;