import React, {useState, useRef, useEffect} from 'react';
import './Hero.css';
import Button from "@material-ui/core/Button";
import {IoArrowForward} from "@react-icons/all-files/io5/IoArrowForward";
import {IoArrowBack} from "@react-icons/all-files/io5/IoArrowBack";
import Aos from "aos";

const Hero = ({ slides }) => {

    const [current, setCurrent] = useState(0);

    const length = slides.length;

    const timeout = useRef(null);

    useEffect(() => {
        Aos.init({duration: 500});
        const nextSlide = () => {
            setCurrent(current => (
                current === length-1 ? 0 : current + 1
            ))
        }

        timeout.current = setTimeout(nextSlide, 5000);
        return function () {
            if(timeout.current)
                clearTimeout(timeout.current);
        }
    }, [current, length])

    const nextSlide = () => {
        if(timeout.current)
            clearTimeout(timeout.current);
        setCurrent(current === length-1 ? 0 : current+1 );
    };

    const prevSlide = () => {
        if(timeout.current)
            clearTimeout(timeout.current);
        setCurrent(current === 0 ? length-1 : current-1)
    };

    if(!Array.isArray(slides) || slides.length <= 0) {
        return null;
    }

    return (
      <div className="heroSection">
          <div className="heroWrapper">
              {slides.map((slide, index) => {
                  return (
                      <div className="heroSlide" key={index}>
                          {index === current && (
                              <div data-aos="fade-zoom-in"
                                   data-aos-easing="ease-in-back"
                                   data-aos-delay="100"
                                   className="heroSlider">
                                  <img className={'heroImage'} src={slide.image} alt={slide.alt} />
                                  <div className={'heroContent'}>
                                      <h1>{slide.title}</h1>
                                      <p>{slide.price}</p>
                                      <Button to={slide.path} primary={true}>
                                          {slide.label}
                                      </Button>
                                  </div>
                              </div>
                          )}

                      </div>
                  )
              })}

              <div className="sliderButtons">
                  <IoArrowBack className={'arrowButton'} onClick={prevSlide}/>
                  <IoArrowForward className={'arrowButton'} onClick={nextSlide}/>
              </div>

          </div>


      </div>



    );
};

export default Hero;
