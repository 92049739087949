const ABOUT_INFO = {
    eng: {
        main_title: 'Rokach Landscape Architecture, Planning and Landscape Development Office',
        sub_titles: [
            {
                sub_title: 'Rokach Landscape Architecture',
                description: [
                    'Landscape Architecture Office for various projects. \n' +
                    'We provide professional services in a variety of garden and landscape development works in the public sector.\n' +
                    'Such as planning Educational Institutions, Residential projects, Commerce, Industry, Public areas, Sheltered housing, Hotels, and more.'
                ]
            },
            {
                sub_title: 'Reliability and professionalism',
                description: [
                    'We deal with project planning, providing professional advice and opinions while emphasizing reliability, adherence to laws and regulations, And thoroughness down to the last detail '
                ]
            },
            {
                sub_title: 'Our Advantages',
                description: [
                    'An office with a young atmosphere and original ideas.',
                    'Up-to-date and innovative design with an emphasis on every detail.',
                    'Thinking about integrating the project with the existing landscape.',
                    'Emphasis on green and economic planning with the appropriate vegetation.'
                ]
            },
            {
                sub_title: 'Our Services',
                description: [
                    'The firm provides each project with escort services, planning and preparation for approval by the authority,' +
                    ' Preparation for the tender that includes a set of plans as needed, including preparation of estimates and statements of quantities and specifications,' +
                    ' Preparation for execution and accompaniment of the contractor, supervision, and production of a report in the delivery of the project.'
                ]
            },
            {
                sub_title: 'Our specialty',
                description: [
                    'Professional, creative and innovative design.',
                    'Planning and finding accessibility solutions.',
                    'Unique vegetation design for each project.',
                    'Treatment of runoff water and drainage of the area.',
                    'Combining unique materials and adapting them to design planning.'
                ]
            },
            {
                sub_title: 'Our approach',
                description: [
                    'Today, the green areas are an expensive and rare resource that must be preserved and nurtured, planning is required in the best and most professional way... that is why we are here'
                ]
            }
        ],
        extras: {
            title: '"I think having land and not ruining it is the most beautiful art that anybody could ever want"',
            description: 'Andy Warhol'
        }
    },
    heb: {
        main_title: 'רוקח אדריכלות נוף משרד תכנון עבודות פיתוח ונוף',
        sub_titles: [
            {
                sub_title: 'רוקח אדריכלות נוף',
                description: [
                    'הינו משרד אדריכלות נוף לפרויקטים בקנ"מ שונים. \n' +
                    'המשרד מספק שירותים מקצועיים במגוון עבודות פיתוח הגנים והנוף במגזר הציבורי.\n' +
                    'כגון: תכנון מסודות חינוך, פרויקטי מגורים, מסחר, תמ"אות, שטחים ציבורים, דיור מוגן, מלונאות ועוד.'
                ]
            },
            {
                sub_title: 'אמינות ומקצועיות',
                description: [
                    'המשרד עוסק בתכנון פרויקטים, מתן יעוץ וחוות דעת מקצועיות תוך שימת דגש על אמינות, הקפדה על חוקים ותקנות – קלה כבחמורה, ויסודיות עד הפרט האחרון.'
                ]
            },
            {
                sub_title: 'היתרונות שלנו',
                description: [
                    'משרד עם אווירה צעירה ורעיונות מקוריים.',
                    'תכנון עדכני וחדשני עם דגש על כל פרט.',
                    'חשיבה על השילוב הפרויקט עם הנוף הקיים.',
                    'דגש על תכנון ירוק וחסכוני עם הצמחייה המתאימה.'
                ]
            },
            {
                sub_title: 'השירותים שלנו',
                description: [
                    'המשרד נותן לכל פרויקט: שירותי ליווי, תכנון, הכנה לאישור ברשות,' +
                    ' הכנה למכרז הכולל סט תוכניות לפי הצורך כולל הכנת אומדנים וכתבי כמויות ומפרטים,' +
                    ' הכנה לביצוע וליווי הקבלן, פיקוח עליון והפקת דו"ח במסירת הפרויקט.'
                ]
            },
            {
                sub_title: 'במה אנחנו מתמחים',
                description: [
                    'תכנון מקצועי, יצירתי וחדשני.',
                    'תכנון ומציאת פתרונות הנגשה.',
                    'תכנון צמחייה יחודית לכל פרויקט.',
                    'טיפול במי נגר וניקוז השטח.',
                    'שילוב חומרים יחודים והתאמתם לתכנון עיצובי.'
                ]
            },
            {
                sub_title: 'הגישה שלנו',
                description: [
                    'כיום השטחים הירוקים הם משאב יקר ונדיר שיש לשמור עליו ולטפח אותו, דרוש תכנון בצורה הטובה והמקצועית ביותר...  בשביל זה אנחנו כאן'
                ]
            }
        ],
        extras: {
                title: '"אני חושב שלהחזיק באדמה ולא להרוס אותה זו האמנות היפה ביותר שמישהו אי-פעם יוכל לרצות להיות הבעלים שלה"',
                description: 'אנדי וורהול'
            }

    }
};

export default ABOUT_INFO;