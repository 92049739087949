export const HOME = {
    id: 'HOME',
    eng:'Home',
    heb: 'בית',
    to: '/'
};
export const ABOUT = {
    id: 'ABOUT',
    eng:'About Us',
    heb: 'עלינו',
    to: '/about'
};
export const PROJECTS = {
    id: 'PROJECTS',
    eng:'Our Projects',
    heb: 'פרויקטים',
    to: '/projects'
};
export const ARTICLES = {
    id: 'ARTICLES',
    eng:'Articles',
    heb: 'כתבות',
    to: '/articles'
};
export const CONTACT = {
    id: 'CONTACT',
    eng:'Contact',
    heb: 'צרו קשר',
    to: '/contact'
};

export const MENU_TYPES_ARRAY = [HOME, ABOUT, PROJECTS, ARTICLES, CONTACT];
