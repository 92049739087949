import { FETCH_ALL } from "../constants/actionTypes";

const articles =  (articles = [] , action) => {
    if (action.type === FETCH_ALL) {
        return action.payload;
    } else {
        return articles;
    }
};

export default articles;